import { i18nExportFormFields } from 'src/components/Data/ExportTemplates/ExportTemplates.i18n';
import { SPECIAL_TABLE_TYPES } from 'state-domains/constants';

import { eventTypesI18n } from '../Events/Events/Events.i18n';

export const i18n: Record<string, any> = {
    name: {
        id: 'filter.name',
        defaultMessage: 'Name',
    },
    description: {
        id: 'filter.description',
        defaultMessage: 'Description',
    },
    category: {
        id: 'filter.category',
        defaultMessage: 'Category',
    },
    source: {
        id: 'filter.soruce',
        defaultMessage: 'Source',
    },
    sourceMx: {
        id: 'filter.sourceMx',
        defaultMessage: 'MX',
    },
    sourceSite: {
        id: 'filter.sourceSite',
        defaultMessage: 'Site',
    },
    usedIn: {
        id: 'filter.usedIn',
        defaultMessage: 'Used in',
    },
    label: {
        id: 'filter.label',
        defaultMessage: 'Label',
    },
    tableType: {
        id: 'filter.tableType',
        defaultMessage: 'Table type',
    },
    tableTypeInterval: {
        id: 'filter.tableTypeInterval',
        defaultMessage: 'Interval',
    },
    tableTypeDepth: {
        id: 'filter.tableTypeDepth',
        defaultMessage: 'Depth',
    },
    tableTypeData: {
        id: 'filter.tableTypeData',
        defaultMessage: 'Data',
    },
    linkedToLithology: {
        id: 'filter.linkedToLithology',
        defaultMessage: 'Linked to lithology',
    },
    isLinkedToLithology: {
        id: 'filter.isLinkedToLithology',
        defaultMessage: 'Is linked to lithology',
    },
    isNotLinkedToLithology: {
        id: 'filter.isNotLinkedToLithology',
        defaultMessage: 'Is not linked to lithology',
    },
    gaps: {
        id: 'filter.gaps',
        defaultMessage: 'Gaps',
    },
    allowGaps: {
        id: 'filter.allowGaps',
        defaultMessage: 'Allow gaps ',
    },
    notAllowGaps: {
        id: 'filter.notAllowGaps',
        defaultMessage: 'Do not allow gaps',
    },
    overlaps: {
        id: 'filter.overlaps',
        defaultMessage: 'Overlaps',
    },
    allowOverlaps: {
        id: 'filter.allowOverlaps',
        defaultMessage: 'Allow overlaps ',
    },
    notAllowOverlaps: {
        id: 'filter.notAllowOverlaps',
        defaultMessage: 'Do not allow overlaps',
    },
    duplicates: {
        id: 'filter.duplicates',
        defaultMessage: 'Duplicates',
    },
    allowDuplicates: {
        id: 'filter.allowDuplicates',
        defaultMessage: 'Allow duplicates ',
    },
    notAllowDuplicates: {
        id: 'filter.notAllowDuplicates',
        defaultMessage: 'Do not allow duplicates',
    },
    tableOrTableView: {
        id: 'filter.tableOrTableView',
        defaultMessage: 'Table or table view',
    },
    childTable: {
        id: 'filter.childTable',
        defaultMessage: 'Child table',
    },
    isChildTable: {
        id: 'filter.isChildTable',
        defaultMessage: 'Is child table',
    },
    isNotChildTable: {
        id: 'filter.isNotChildTable',
        defaultMessage: 'Is not child table',
    },
    table: {
        id: 'filter.table',
        defaultMessage: 'Table',
    },
    tableView: {
        id: 'filter.tableView',
        defaultMessage: 'Table view',
    },
    headerType: {
        id: 'filter.headerType',
        defaultMessage: 'Header type',
    },
    dataType: {
        id: 'filter.dataType',
        defaultMessage: 'Data type',
    },
    text: {
        id: 'filter.text',
        defaultMessage: 'Text',
    },
    numeric: {
        id: 'filter.numeric',
        defaultMessage: 'Numeric',
    },
    checkbox: {
        id: 'filter.checkbox',
        defaultMessage: 'Checkbox',
    },
    file: {
        id: 'filter.file',
        defaultMessage: 'File',
    },
    list: {
        id: 'filter.list',
        defaultMessage: 'List',
    },
    date: {
        id: 'filter.date',
        defaultMessage: 'Date',
    },
    filter: {
        id: 'filter.filterLists',
        defaultMessage: 'Filter',
    },
    assignedTables: {
        id: 'filter.assignedTables',
        defaultMessage: 'Assigned tables',
    },
    assignedHeaders: {
        id: 'filter.assignedHeaders',
        defaultMessage: 'Assigned headers',
    },
    assignedActivities: {
        id: 'filter.assignedActivities',
        defaultMessage: 'Assigned activities',
    },
    assignedUsers: {
        id: 'filter.assignedUsers',
        defaultMessage: 'Assigned users',
    },
    isGeographic: {
        id: 'filter.isGeographic',
        defaultMessage: 'Is geographic',
    },
    geographic: {
        id: 'filter.geographic',
        defaultMessage: 'Geographic',
    },
    projection: {
        id: 'filter.projection',
        defaultMessage: 'Projection',
    },
    world: {
        id: 'filter.world',
        defaultMessage: 'World',
    },
    custom: {
        id: 'filter.custom',
        defaultMessage: 'Custom',
    },
    module: {
        id: 'filter.module',
        defaultMessage: 'Module',
    },
    specialTable: {
        id: 'special.table',
        defaultMessage: 'Special table',
    },
    [SPECIAL_TABLE_TYPES.LITHOLOGY]: {
        id: 'lithology',
        defaultMessage: 'Lithology',
    },
    [SPECIAL_TABLE_TYPES.SAMPLES]: {
        id: 'samples',
        defaultMessage: 'Samples',
    },
    [SPECIAL_TABLE_TYPES.STRUCTURE]: {
        id: 'structure',
        defaultMessage: 'Structure',
    },
    [SPECIAL_TABLE_TYPES.SURVEY]: {
        id: 'survey',
        defaultMessage: 'Survey',
    },
    [SPECIAL_TABLE_TYPES.XRF]: {
        id: 'xrf',
        defaultMessage: 'XRF',
    },
    [SPECIAL_TABLE_TYPES.OTHER]: {
        id: 'other',
        defaultMessage: 'Other',
    },
    hasFiles: {
        id: 'has-files',
        defaultMessage: 'Has Files',
    },
    doesntHaveFiles: {
        id: 'doesnt-have-files',
        defaultMessage: 'Does Not Have Files',
    },
    destination: {
        id: 'destination',
        defaultMessage: 'Destination',
    },
    shared: {
        id: 'shared',
        defaultMessage: 'Shared',
    },
    isShared: {
        id: 'is_Shared',
        defaultMessage: 'Is shared',
    },
    isNotShared: {
        id: 'is_not_shared',
        defaultMessage: 'Is not shared',
    },
    selectedTables: {
        id: 'filter.selectedTables',
        defaultMessage: 'Selected tables',
    },
    selectedHeaders: {
        id: 'filter.assignedHeaders',
        defaultMessage: 'Selected headers',
    },
    project: {
        id: 'filter.project',
        defaultMessage: 'Project',
    },
    doesIncludeFiles: {
        id: 'filter.includesFiles',
        defaultMessage: 'Includes files',
    },
    doesntIncludeFiles: {
        id: 'filter.doesntIncludeFile',
        defaultMessage: 'Does not include files',
    },
    doesIncludeMetadata: {
        id: 'filter.includesMetadata',
        defaultMessage: 'Includes metadata',
    },
    doesntIncludeMetadata: {
        id: 'filter.doesntIncludeMetadata',
        defaultMessage: 'Does not include metadata',
    },
    validity: {
        id: 'validity',
        defaultMessage: 'Validity',
    },
    isValid: {
        id: 'is_valid',
        defaultMessage: 'Is valid',
    },
    isNotValid: {
        id: 'is_not_valid',
        defaultMessage: 'Is not valid',
    },
    ...i18nExportFormFields,
    ...eventTypesI18n,
};
